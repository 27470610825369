import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-quill/dist/quill.snow.css";
import "react-image-crop/dist/ReactCrop.css";
import "./pages/Challenge/header.css";
import "./App.css";

import "./pages/University/Home/Main.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./pages/Home/home.css";
import "./pages/JobPost/CreateJob/createjob.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { Suspense, useState } from "react";
import "./components/Card/card.css";
import "./components/Company/create/body.css";
import "./components/Company/Dashboard/analytics.css";
import "./components/Company/Dashboard/nav.css";
import "./components/Company/Dashboard/resulttable.css";
import "./components/Company/Dashboard/second.css";
import "./components/Company/Dashboard/showall.css";
import "./components/Company/Dashboard/talent.css";
import "./components/Company/field/input.css";
import "./components/Company/Find/company.css";
import "./components/Company/Footer/footer.css";
import "./components/Company/styles.scss";

import { useSelector } from "react-redux";

import PrimaryRouteLayout from "./layouts/PrimaryRouteLayout";

const StudentOrgHome = React.lazy(() =>
  import("./pages/StudentOrganization/Home")
);
const Webinar = React.lazy(() => import("./pages/Employer/Webinar"));
const Success = React.lazy(() => import("./pages/Student/Success"));

const EmployerJobpost = React.lazy(() =>
  import("./pages/NewPages/Jobpost/Main")
);
const EmployerMessenger = React.lazy(() =>
  import("./pages/NewPages/Messenger/Main")
);

const FounderHub = React.lazy(() => import("./pages/FounderHub/Main"));
const Test = React.lazy(() => import("./pages/Challenge/Challenges"));
const Main = React.lazy(() => import("./pages/Challenge/Main"));
const StudentReg = React.lazy(() =>
  import("./components/StudentRegister/Register/main")
);
const PreviewPage = React.lazy(() => import("./pages/PreviewPage/PreviewPage"));
const Login = React.lazy(() => import("./pages/Login/StudentLogin/Login"));
const EditChallenge = React.lazy(() =>
  import("./pages/Challenge/EditChallenge")
);
const Jobpost = React.lazy(() => import("./pages/JobPost/Main"));
const CreateJob = React.lazy(() =>
  import("./pages/JobPost/CreateJob/CreateJob")
);
const Editjob = React.lazy(() => import("./pages/JobPost/CreateJob/EditJob"));
const Settings = React.lazy(() => import("./pages/Settings/Settings"));
const Company = React.lazy(() => import("./pages/CompanyLogin/Login"));
const DashboardMain = React.lazy(() =>
  import("./pages/MainDashBoard/DashboardMain")
);
const TalentPool = React.lazy(() => import("./pages/TalentPool/Main"));
const StudentProfilePage = React.lazy(() =>
  import("./pages/StudentProfilePage/StudentProfilePage")
);
const ShortList = React.lazy(() =>
  import("./pages/TalentPool/ShortListed/Shortlist")
);
const Preview = React.lazy(() => import("./pages/CompanyPreview/Preview"));
const TargetSchool = React.lazy(() =>
  import("./pages/TalentPool/TargetedSchools/Targeted")
);
const Team = React.lazy(() => import("./pages/TeamPage/Team"));
const InviteRegister = React.lazy(() =>
  import("./pages/TeamPage/InviteRegister")
);
const Testing = React.lazy(() => import("./pages/Testing"));
const CompanyEdit = React.lazy(() => import("./pages/CompanyEdit/Edit"));
const Terms = React.lazy(() => import("./pages/Terms/Term"));
const Privacy = React.lazy(() => import("./pages/Privacy/Privacy"));
const Cookie = React.lazy(() => import("./pages/Cookie/Cookie"));
const JobFlow = React.lazy(() => import("./pages/StudentJobFlow/Jobflow"));
const SocialMain = React.lazy(() => import("./pages/SocialFeed/SocialMain"));
const StudentSettings = React.lazy(() =>
  import("./pages/StudentSettings/Settings")
);
const NewDashboard = React.lazy(() => import("./pages/Challenge/NewDashboard"));
const CompanyChat = React.lazy(() => import("./pages/CompanyChat/Main"));
const ForgotPassword = React.lazy(() =>
  import("./pages/CompanyLogin/ForgotPassword")
);
const CreateNewPassword = React.lazy(() =>
  import("./pages/CompanyLogin/CreateNewPassword")
);
const EventMain = React.lazy(() => import("./pages/Events/Main"));
const CreateEvent = React.lazy(() => import("./pages/CreatEvent/Main"));
const EditEvent = React.lazy(() => import("./pages/CreatEvent/EditEvent"));
const Progress = React.lazy(() => import("./pages/Progress/main"));
const SelectedView = React.lazy(() =>
  import("./pages/Events/Upcoming/SelectedView")
);
const ChallengeDetails = React.lazy(() =>
  import("./pages/Challenge/ChallengeDetails")
);
const Recruiter = React.lazy(() => import("./pages/Profile/Recruiter"));
const Details = React.lazy(() => import("./pages/JobDetails/Details"));
const NewProfile = React.lazy(() =>
  import("./pages/NewCompanyProfile/Profile")
);
const SupportNew = React.lazy(() => import("./pages/SupportNew/SupportNew"));
const BookDemo = React.lazy(() => import("./pages/BookDemo/BookDemo"));
const ScrollToTop = React.lazy(() => import("./pages/ScrollTop/TopScroll"));
const LatestStudentDashboard = React.lazy(() =>
  import("./pages/NewStudentDashboard/Main")
);
const NewStudentChallenges = React.lazy(() =>
  import("./pages/Student/Challenge/Main")
);
const StudentChallengeMain = React.lazy(() =>
  import("./pages/StudentChallenge/Main")
);
const NewResult = React.lazy(() => import("./pages/ChallengeResult/NewResult"));
const Solutions = React.lazy(() => import("./pages/NewPages/Page1"));
const PublicTour = React.lazy(() => import("./pages/PublicTour/Main"));
const EventsHome = React.lazy(() =>
  import("./pages/NewPages/Events/EventsHome")
);
const AnalyticsHome = React.lazy(() =>
  import("./pages/NewPages/Analytics/AnalyticsHome")
);
const StudentJobDetails = React.lazy(() =>
  import("./pages/StudentJobFlow/JobDetails")
);
const MainEventsStudent = React.lazy(() =>
  import("./pages/StudentEventFlow/Main")
);
const MainEventDetails = React.lazy(() =>
  import("./pages/StudentEventDetails/MainEventDetails")
);
const StudentGroupMain = React.lazy(() => import("./pages/StudentGroups/Main"));
const GroupDetails = React.lazy(() =>
  import("./pages/StudentGroups/GroupDetails/Main")
);
const NewStudentProfile = React.lazy(() =>
  import("./pages/StudentDashboardProfile/NewProfile")
);
const MainCertificate = React.lazy(() =>
  import("./pages/Certificate/Certificate")
);
const OnePost = React.lazy(() => import("./pages/SinglePost/Single"));
const CorporateTrack = React.lazy(() =>
  import("./pages/CorporateTrack/CorporateTrack")
);
const InnovativeTrack = React.lazy(() =>
  import("./pages/innovation-track/innovation-track")
);
const DummyHero = React.lazy(() => import("./pages/DummyHero/DummyHero"));

const NewHome = React.lazy(() => import("./pages/Home/NewHome"));
// import Toolkit from "./pages/University/Toolkit/Main";
const Toolkit = React.lazy(() => import("./pages/University/Toolkit/Main"));
// import Contact from "./pages/Contact/Contact";
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
// import CompanyHome from "./pages/CompanyHome/Home";
const CompanyHome = React.lazy(() => import("./pages/CompanyHome/Home"));
// import UniversityHome from "./pages/University/Home/Main";
const UniversityHome = React.lazy(() => import("./pages/University/Home/Main"));
// import UniversityRegister from "./pages/University/Register/Main";
const UniversityRegister = React.lazy(() =>
  import("./pages/University/Register/Main")
);
// import FindUni from "./pages/University/Register/FindUni";
const FindUni = React.lazy(() => import("./pages/University/Register/FindUni"));

// import CareerContact from "./pages/CareerContact/Contact";
const CareerContact = React.lazy(() => import("./pages/CareerContact/Contact"));
// import CompanyRegister from "./pages/Company/Register";
const CompanyRegister = React.lazy(() => import("./pages/Company/Register"));
// import EmailPage from "./pages/Company/EmailPage";
const EmailPage = React.lazy(() => import("./pages/Company/EmailPage"));
// import FindCompany from "./pages/Company/FindCompany";
const FindCompany = React.lazy(() => import("./pages/Company/FindCompany"));
// import CreateCompany from "./pages/Company/CreateCompany";
const CreateCompany = React.lazy(() => import("./pages/Company/CreateCompany"));
// import Role from "./pages/register/Role";
const Role = React.lazy(() => import("./pages/register/Role"));
// import Profile from "./pages/user/profile";
const Profile = React.lazy(() => import("./pages/user/profile"));
// import Guide from "./pages/GuideLine/Guideline";
const Guide = React.lazy(() => import("./pages/GuideLine/Guideline"));
// import StudentLinkedinRegister from "./pages/Linkedin/StudentRegister";
const StudentLinkedinRegister = React.lazy(() =>
  import("./pages/Linkedin/StudentRegister")
);
// import Register from "./pages/Linkedin/register";
const Register = React.lazy(() => import("./pages/Linkedin/register"));

// import LoginLinkedin from "./pages/Linkedin/login";
const LoginLinkedin = React.lazy(() => import("./pages/Linkedin/login"));

// import LinkedinStudentLogin from "./pages/Linkedin/StudentLogin";
const LinkedinStudentLogin = React.lazy(() =>
  import("./pages/Linkedin/StudentLogin")
);
function App() {
  const OrganiserLogin = useSelector((state) => Boolean(state.uniqueId));
  const [student, setStudent] = useState(null);

  return (
    <>
      <ToastContainer />
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader" />
          </div>
        }
      >
        <Router>
          <ScrollToTop student={student} setStudent={setStudent} />

          <Routes>
            {/* <RouterProvider router={router} /> */}
            <Route
              path="/"
              element={
                OrganiserLogin ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <PrimaryRouteLayout />
                )
              }
            >
              <Route index element={<NewHome />} />
              <Route path="/home" element={<NewHome />} />
            </Route>
            <Route path="/student-organisations" element={<StudentOrgHome />} />
            <Route
              path="/career-centers/marketing-toolkit/"
              element={<Toolkit />}
            />
            <Route path="/career-centers/webinars/" element={<Webinar />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/employer" element={<CompanyHome />} />
            <Route path="/career-centers" element={<UniversityHome />} />
            <Route
              path="/career-center/register"
              element={<UniversityRegister />}
            />
            <Route path="/career-center/find/:uniqueId" element={<FindUni />} />
            <Route path="/career-center/contact" element={<CareerContact />} />
            <Route path="/employer/register" element={<CompanyRegister />} />
            <Route
              path="/student/register"
              element={<StudentLinkedinRegister />}
            />
            <Route path="/employer/register/verify" element={<EmailPage />} />
            <Route path="/register/linkedin" element={<Register />} />
            <Route path="/login/linkedin" element={<LoginLinkedin />} />
            <Route
              path="/login/linkedin/student"
              element={<LinkedinStudentLogin />}
            />
            <Route path="/Find/:uniqueId" element={<FindCompany />} />

            <Route path="/register" element={<StudentReg />} />
            <Route
              path="/register/success/not/reg/first/fmasnmfnjsbfj"
              element={<Success />}
            />
            <Route path="/create/company" element={<CreateCompany />} />

            <Route path="/login" element={<Login />} />
            <Route path="/create/company/role" element={<Role />} />
            <Route path="/support" element={<SupportNew />} />
            <Route path="/employer/support" element={<SupportNew />} />

            <Route path="/guidelines" element={<Guide />} />
            <Route
              path="/challenge/:uniqueId"
              element={<PreviewPage user={student} />}
            />
            <Route path="/preview/:uniqueId" element={<Preview />} />
            <Route
              path="/challenge/attempt/:uniqueId"
              element={<StudentChallengeMain student={student} />}
            />

            <Route path="/challenge/result/:id" element={<NewResult />} />
          {/* <Route path="/test" element={<Testing />} /> */}

            <Route
              path="/company/edit/:uniqueId"
              element={
                OrganiserLogin ? (
                  <CompanyEdit />
                ) : (
                  <Navigate to="/employer/login" />
                )
              }
            />

            <Route path="/edit/challenge" element={<EditChallenge />} />
            <Route path="/edit/job" element={<Editjob />} />

            <Route path="/employer/login" element={<Company />} />
            <Route path="/corporate-track" element={<CorporateTrack />} />
            <Route path="/innovation-track" element={<InnovativeTrack />} />
            <Route path="/founders-hub" element={<FounderHub />} />
            <Route
              path="/student"
              element={
                <LatestStudentDashboard
                  student={student}
                  setStudent={setStudent}
                />
              }
            >
              <Route index element={<SocialMain user={student} />} />

              <Route path="post/:id" element={<OnePost user={student} />} />
              <Route
                path="user/:link"
                element={
                  <NewStudentProfile
                    watcher={student}
                    setWatcher={setStudent}
                  />
                }
              />
              <Route
                path="groups"
                element={<StudentGroupMain student={student} />}
              />
              <Route
                path="group/:link"
                element={<GroupDetails student={student} />}
              />
              <Route
                path="challenges"
                element={<NewStudentChallenges student={student} />}
              />
              <Route path="jobs" element={<JobFlow />} />
              <Route
                path="events"
                element={<MainEventsStudent user={student} />}
              />
              <Route
                path="event/:link"
                element={<MainEventDetails user={student} />}
              />
              <Route path="job/:uniqueId" element={<StudentJobDetails />} />
              <Route
                path="settings"
                element={<StudentSettings user={student} />}
              />
            </Route>

            <Route
              path="/dashboard"
              element={
                OrganiserLogin ? (
                  <NewDashboard />
                ) : (
                  <Navigate to="/employer/login" />
                )
              }
            >
              <Route index element={<DashboardMain />} />
              <Route path="u/:url" element={<Recruiter />} />
              <Route path="progress" element={<Progress />} />
              <Route path="book-tour" element={<BookDemo />} />
              <Route path="chat" element={<CompanyChat />} />
              <Route path="company/:uniqueId" element={<NewProfile />} />
              <Route path="events" element={<EventMain />} />
              <Route path="events/details" element={<SelectedView />} />
              <Route path="user/:uniqueId" element={<StudentProfilePage />} />
              <Route path="TalentPool" element={<TalentPool />} />
              <Route path="TalentPool/Short-list" element={<ShortList />} />
              <Route
                path="TalentPool/TargetSchool"
                element={<TargetSchool />}
              />
              <Route path="Challenges" element={<Test />} />
              <Route path="challenge/details" element={<ChallengeDetails />} />
              <Route path="Team" element={<Team />} />
              <Route path="Jobposts" element={<Jobpost />} />
              <Route path="job/details/:uniqueId" element={<Details />} />

              <Route path="settings" element={<Settings />} />
            </Route>

            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/cookie" element={<Cookie />} />

            <Route
              path="/create/challenge"
              element={
                OrganiserLogin ? <Main /> : <Navigate to="/employer/login" />
              }
            />
            <Route path="/employer/book-a-tour" element={<PublicTour />} />

            <Route
              path="/employer/solutions-challenges"
              element={<Solutions />}
            />
            <Route path="/employer/events" element={<EventsHome />} />
            <Route path="/employer/analytics" element={<AnalyticsHome />} />
            <Route path="/employer/messenger" element={<EmployerMessenger />} />
            <Route path="/employer/job-posts" element={<EmployerJobpost />} />
            <Route
              path="/create/job"
              element={
                OrganiserLogin ? (
                  <CreateJob />
                ) : (
                  <Navigate to="/employer/login" />
                )
              }
            />
            <Route
              path="/create/event"
              element={
                OrganiserLogin ? (
                  <CreateEvent />
                ) : (
                  <Navigate to="/employer/login" />
                )
              }
            />
            <Route
              path="/edit/event"
              element={
                OrganiserLogin ? (
                  <EditEvent />
                ) : (
                  <Navigate to="/employer/login" />
                )
              }
            />
            <Route
              path="/employer/forgot/password"
              element={<ForgotPassword />}
            />
            {/* <Route path="/dummy-hero" element={<DummyHero />} /> */}
            <Route
              path="/employer/recover/:uniqueId"
              element={<CreateNewPassword />}
            />
            <Route
              path="/registration/:company/:dsa/:user"
              element={<InviteRegister />}
            />
            <Route
              path="/create/profile/:uniqueId/:dsa/:company/:user"
              element={<Profile />}
            />
            <Route
              path="/student/certificate/:id"
              element={<MainCertificate />}
            />
            <Route path="/create/company/role/:Role" element={<Profile />} />

            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
